import React from "react";

export default () =>
<div className="footer">
<div className="footer-inner">
    <div className="footer-left">
        <div className="footer-logo">

        </div>
        <ul className="footer-links">
            <li className="nav-item"><a href="/about">About</a></li>
            <li className="nav-item"><a href="/news">News</a></li>
            <li className="nav-item"><a href="/partner">Partner</a></li>
            <li className="nav-item"><a href="/donate">Donate</a></li>
            <li className="nav-item signup"><a href="/sign-up">Join</a></li>
            <li className="nav-item"><a href="/contact">Contact</a></li>
        </ul>
    </div>
    <div className="footer-right">
        <ul className="footer-links">
            <li className="nav-item"><a href="https://www.facebook.com/globalinheritance" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li className="nav-item"><a href="http://www.flickr.com/photos/74696088@N00/sets/" target="_blank"><i className="fa fa-flickr" aria-hidden="true"></i></a></li>
            <li className="nav-item"><a href="https://www.instagram.com/globalinheritance/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li className="nav-item"><a href="https://twitter.com/glblinheritance" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li className="nav-item"><a href="http://www.youtube.com/globalinheritance" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
        </ul>
    </div>
</div>
</div>
