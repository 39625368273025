import React, { Fragment, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Footer from "./Footer";
import Header from "./Header";
import '../../stylesheets/styles.scss'

export default props => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={ data => <Layout data={ data } { ...props }/> }
  />
)

const Layout = (props) => {
  // Define the meta title and description
  const title = props.data.site.siteMetadata.title
  const description = props.data.site.siteMetadata.description;
  
  useEffect(() => {
    if (window.location.href.indexOf("#") < 0) {
      window.scrollTo(0, 0);
    }

    window.ldfdr = window.ldfdr || {};
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://sc.lfeeder.com/lftracker_v1_kn9Eq4RdvAK8RlvP.js';    

    document.head.appendChild(script);
  }, []);
	return(
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ title }</title>
        <meta name="description" content={ description } />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        {/* <link href="https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css" type="text/css" rel="stylesheet" /> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js"></script>
        {/* <script src="https://cdn.snipcart.com/scripts/2.0/snipcart.js" id="snipcart" data-api-key="ZDczZmQ1ZDMtMDJlZS00ODhmLTk2Y2YtNzQwMjMxYjRjNWRkNjM2NTA2MjU2MjU1NDkyNzE5"></script> */}
        <script async src="https://widgets.givebutter.com/latest.umd.cjs?acct=QV0Ckd0623m7eJUB&p=other"></script>

      </Helmet>
      <nav>
        <Header navBg={props.navBg} />
      </nav>
      <main>
        { props.children }
      </main>
      <Footer/>
    </Fragment>
	)
}